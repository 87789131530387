import { CampaignApi } from '../api/campaign'
import CampaignCardGrid from '../components/CampaignCardGrid'
import Spinner from '../components/Spinner'
import { useRagStore } from '../context/rag/store'
import { useUserStore } from '../context/user/store'
import { Page } from '../enums/Page'
import { CampaignsResponse } from '../types/CreatedCampaignsResponse'
import { useEffect, useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

const Home = (): JSX.Element => {
    const navigate = useNavigate()
    const [campaignsResponse, setCampaignsResponse] = useState<CampaignsResponse | null>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const {
        state: { user },
    } = useUserStore()
    const { setInterviewIds, setSurveyIds } = useRagStore()

    const createCampaign = () => {
        navigate(
            generatePath(Page.CAMPAIGN_LANDING_PAGE, {
                username: user.username,
                id: 'new',
            }),
        )
    }

    const selectCampaign = (campaignId: string) => {
        navigate(
            generatePath(Page.CAMPAIGN_LANDING_PAGE, {
                username: user.username,
                id: campaignId || '',
            }),
        )
    }

    const fetchCreatedCampaigns = async () => {
        try {
            setLoading(true)
            const response = await CampaignApi.getCreatedCampaigns()
            // eslint-disable-next-line no-console
            setCampaignsResponse(response.data)
        } catch (err) {
            // eslint-disable-next-line no-console
            setCampaignsResponse(null)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        setSurveyIds([])
        setInterviewIds([])
    }, [])

    useEffect(() => {
        fetchCreatedCampaigns()
    }, [])
    return (
        <div className="flex-1 pl-5 pr-3 sm:px-[70px] lg:pr-[120px]">
            <div className="flex items-center gap-5 justify-between mb-10">
                <h2 className="text-[25px] font-bold text-[#8092A5]">Campaigns</h2>
                <div className="drop-shadow-2xl">
                    <button
                        className="flex justify-center items-center bg-[#007AFF] rounded-full text-base text-white p-1.5"
                        onClick={createCampaign}
                    >
                        <img
                            src="/asset/plus-icon.svg"
                            alt="plus-icon"
                            className="w-[32px] h-[32px] drop-shadow-2xl"
                        />
                    </button>
                </div>
            </div>
            <div className="flex-1">
                <div className="mt-5 flex flex-col gap-4 overflow-y-auto pb-8 pr-2">
                    {!campaignsResponse ||
                    !campaignsResponse.campaigns ||
                    campaignsResponse?.campaigns.length === 0 ? (
                        <div className="flex-1 pt-[185px] text-center">
                            <p className="text-[25px] text-[#BFDEFF]">
                                Start by creating your first campaign
                            </p>
                        </div>
                    ) : (
                        <>
                            <CampaignCardGrid
                                campaigns={campaignsResponse?.campaigns}
                                onCampaignClick={selectCampaign}
                            />
                        </>
                        // campaignsResponse?.campaigns.map((campaign: Campaign) => (
                        //     <div
                        //         key={campaign.id}
                        //         className="cursor-pointer"
                        //         onClick={() => {
                        //             if (campaign.type === CampaignType.LIVE) {
                        //                 navigate(
                        //                     generatePath(Page.INTERVIEW_LANDING_PAGE, {
                        //                         username: user?.username,
                        //                         slug: campaign.slug,
                        //                     }),
                        //                 )
                        //             } else {
                        //                 navigate(
                        //                     generatePath(Page.QUESTION_LANDING_PAGE, {
                        //                         username: user?.username,
                        //                         slug: campaign.slug,
                        //                     }),
                        //                 )
                        //             }
                        //         }}
                        //     >
                        //         <CreatedCampaignCard campaign={campaign} />
                        //     </div>
                        // ))
                    )}
                </div>
            </div>
            {loading && <Spinner />}
        </div>
    )
}

export default Home
