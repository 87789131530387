import types, { RagAction } from './types'
import { Dispatch } from 'react'

export const setSurveyIdsAction = (surveyIds: string[], dispatch: Dispatch<RagAction>): void => {
    dispatch({
        type: types.SET_SURVEY_IDS,
        payload: { surveyIds },
    })
}

export const setInterviewIdsAction = (
    interviewIds: string[],
    dispatch: Dispatch<RagAction>,
): void => {
    dispatch({
        type: types.SET_INTERVIEW_IDS,
        payload: { interviewIds },
    })
}
