import { RagApi } from '../api/rag'
import { useRagStore } from '../context/rag/store'
import { RagQueryResponseSingleResult } from '../types/RagQueryResponse'
import Logo from './Logo'
import SearchButton from './SearchButton'
import SearchResultCard from './SearchResultCard'
import { useState } from 'react'

interface SearchComponentProps {
    setBlurBackground: (value: boolean) => void
}

export default function SearchComponent({ setBlurBackground }: SearchComponentProps) {
    const [searchResults, setSearchResults] = useState<RagQueryResponseSingleResult[] | null>(null)
    const [query, setQuery] = useState<string>('')
    const [currentQueryString, setCurrentQueryString] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)
    const [isFocused, setIsFocused] = useState(false)
    const {
        state: { surveyIds, interviewIds },
    } = useRagStore()

    const search = async (query: string) => {
        setIsLoading(true)
        setError(null)
        setBlurBackground(true)
        try {
            const response = await RagApi.query({
                query: query,
                surveyIds: surveyIds,
                interviewIds: interviewIds,
            })
            if (response && response.data && Array.isArray(response.data)) {
                setSearchResults(response.data)
            } else {
                throw new Error('Invalid response format')
            }
        } catch (err) {
            setError('An error occurred while searching. Please try again.')
            setSearchResults(null)
        } finally {
            setIsLoading(false)
        }
    }

    const handleClose = () => {
        // eslint-disable-next-line no-console
        //console.log('handleClose')
        setSearchResults(null)
        setBlurBackground(false)
        setCurrentQueryString('')
        setQuery('')
        setError(null)
        setIsFocused(false)
    }

    const handleSearch = async () => {
        if (searchResults || error) {
            setSearchResults(null)
            setError(null)
        }
        if (query.trim()) {
            setCurrentQueryString(query)
            setQuery('')
            await search(query)
        }
    }

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleSearch()
        }
    }

    const handleFocus = () => {
        setIsFocused(true)
    }

    const handleBlur = (e: any) => {
        // eslint-disable-next-line no-console
        //console.log('handleBlur: ', e)
        //console.log('handleBlur: ', isFocused, searchResults, error)
        if (e.relatedTarget === null && isFocused && (searchResults || error)) {
            //handleClose()
        }
    }

    return (
        <div className="w-full max-w-5xl mx-auto p-4" onFocus={handleFocus} onBlur={handleBlur}>
            <div className="relative flex items-center shadow-lg rounded-full bg-white pl-3 py-2">
                <div className="flex-shrink-0 mr-2">
                    <Logo />
                </div>
                <input
                    type="text"
                    placeholder="What content do you want to find?"
                    className="pl-2 pr-20 py-3 w-full rounded-full border-none focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    onKeyPress={handleKeyPress}
                />
                <button
                    type="submit"
                    className="absolute right-2 top-1/2 transform -translate-y-1/2 rounded-full transition-colors duration-200"
                    aria-label="Search"
                    onClick={handleSearch}
                    disabled={isLoading}
                >
                    <SearchButton />
                </button>
            </div>
            {isLoading && (
                <div className="max-h-screen sm:max-h-[600px] md:max-h-[800px] mt-4 bg-white rounded-lg shadow-md h-screen overflow-y-auto">
                    <div className="mt-4 text-center text-lg font-bold">
                        <p>Searching for "{currentQueryString}"</p>
                    </div>
                </div>
            )}
            {error && (
                <div className="max-h-screen sm:max-h-[600px] md:max-h-[800px] mt-4 bg-white rounded-lg shadow-md h-screen overflow-y-auto">
                    <div className="flex flex-row justify-between items-center p-4">
                        <p className="text-lg font-bold ml-10 w-4/5">
                            Searched for "{currentQueryString}"
                        </p>
                        <button className="flex justify-end pt-2 pr-2 w-1/5" onClick={handleClose}>
                            <img src="/asset/close-modal-icon.svg" alt="Close" />
                        </button>
                    </div>
                    <div className="mt-4 text-center text-red-500">
                        <p>{error}</p>
                    </div>
                </div>
            )}
            {searchResults && (
                <div className="max-h-screen sm:max-h-[600px] md:max-h-[800px] mt-4 bg-white rounded-lg shadow-md h-screen overflow-y-auto">
                    <div className="flex flex-row justify-between items-center p-4">
                        <p className="text-lg font-bold ml-10 w-4/5">{currentQueryString}</p>
                        <button className="flex justify-end pt-2 pr-2 w-1/5" onClick={handleClose}>
                            <img src="/asset/close-modal-icon.svg" alt="Close" />
                        </button>
                    </div>
                    <SearchResultCard results={searchResults} />
                </div>
            )}
            {searchResults && searchResults.length === 0 && (
                <div className="max-h-screen sm:max-h-[600px] md:max-h-[800px] mt-4 bg-white rounded-lg shadow-md h-screen overflow-y-auto">
                    <div className="flex flex-row justify-between items-center p-4">
                        <p className="text-lg font-bold ml-10 w-4/5">
                            Searched for "{currentQueryString}"
                        </p>
                        <button className="flex justify-end pt-2 pr-2 w-1/5" onClick={handleClose}>
                            <img src="/asset/close-modal-icon.svg" alt="Close" />
                        </button>
                    </div>
                    <div className="mt-4 text-center">
                        <p>No results found. Please try a different search query.</p>
                    </div>
                </div>
            )}
        </div>
    )
}
