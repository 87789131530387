//import { useQuestionStore } from '../context/question/store'
//import { toMMHH } from '../utils/toMMHH'
//import { Tooltip } from 'flowbite-react'
import getBlobDuration from 'get-blob-duration'
import { useRef } from 'react'
//import { useAudioRecorder } from 'react-audio-voice-recorder'
//import { useTranslation } from 'react-i18next'
import VideoRecorder from 'react-video-recorder'

interface VideoButtonProps {
    onStop: (formData: FormData, audioLength: number) => void
    onRecorderNotSupported: () => void
    disabled: boolean
    tooltip?: string
    isQuestionResponder?: boolean
    //isLiveMode?: boolean
    setIsRecording?: (v: boolean | ((prevVar: boolean) => boolean)) => void
}

const VideoButton = ({
    onStop,
    //onRecorderNotSupported,
    //disabled = false,
    //tooltip,
    //isQuestionResponder = false,
    setIsRecording,
}: //isLiveMode = false,
VideoButtonProps): JSX.Element => {
    // const {
    //     state: { accepted },
    //     setShowTip,
    //     setOpenFooter,
    //     setQuestionResponderLanded,
    // } = useQuestionStore()

    //const { t } = useTranslation()

    // const StartRecordingIcon = () => (
    //     <div className="p-[28px] bg-[#d8ebff] rounded-full">
    //         <div className="p-[25px] bg-[#007AFF] rounded-full">
    //             <img src="/asset/video.svg" />
    //         </div>
    //     </div>
    // )

    // const StopRecordingIcon = () => (
    //     <div className="p-[28px] bg-[#d8ebff] rounded-full">
    //         <div className="p-[25px] bg-[#007AFF] rounded-full">
    //             <div className="w-[46px] h-[46px] bg-[#DF2D2D] rounded-md" />
    //         </div>
    //     </div>
    // )

    const playerRef = useRef<HTMLVideoElement>(null)
    //const recorderControls = useAudioRecorder()

    const addVideoElement = (blob: Blob) => {
        const url = URL.createObjectURL(blob)
        const video = document.createElement('video')
        video.src = url
        video.controls = true
        document.body.appendChild(video)
    }

    return (
        <div className="w-full sm:w-[640px] h-full sm:h-[480px]">
            {/* @ts-ignore-next-line */}
            <VideoRecorder
                disabled={true}
                isOnInitially
                showReplayControls
                isFlipped={false}
                disablePictureInPicture
                showSwitchCameraButton={false}
                videoControlsList="nodownload"
                // mimeType={text('mimeType')}
                countdownTime={0}
                timeLimit={300000}
                replayVideoAutoplayAndLoopOff
                onStartRecording={() => {
                    // eslint-disable-next-line no-console
                    console.log('Recording started')
                    setIsRecording && setIsRecording(true)
                }}
                onRecordingComplete={async (videoBlob: any) => {
                    // Do something with the video...
                    // eslint-disable-next-line no-console
                    console.log('videoBlob', videoBlob)
                    addVideoElement(videoBlob)
                    const videoURL = URL.createObjectURL(videoBlob)
                    const formData = new FormData()
                    const videoLength = await getBlobDuration(videoBlob)
                    const name = videoURL.split('/')[videoURL.split('/').length - 1]
                    formData.append('file', videoBlob, `${name}`)
                    formData.append('audio_length', String(videoLength * 1000))
                    setIsRecording && setIsRecording(false)
                    onStop(formData, videoLength)
                    if (playerRef.current) {
                        playerRef.current.src = videoURL
                    }
                }}
            />
        </div>
    )
}

export default VideoButton
