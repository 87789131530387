import { useQuestionStore } from '../context/question/store'
import { useUserStore } from '../context/user/store'
import { Page } from '../enums/Page'
import { NavItem } from '../types/Layout'
import { sideNavs } from '../utils/constant'
import SearchComponent from './SearchComponent'
import Spinner from './Spinner'
import { Dropdown } from 'flowbite-react'
import { useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

interface LayoutProps {
    children: JSX.Element | JSX.Element[]
}

const Layout = ({ children }: LayoutProps): JSX.Element => {
    const [isRightMenuExpanded, setIsRightMenuExpanded] = useState<boolean>(false)
    const [selectedNavbar, setSelectedNavbar] = useState<string>(sideNavs[0].id)
    const [blurBackground, setBlurBackground] = useState<boolean>(false)
    const {
        state: { user, loading },
        logout,
    } = useUserStore()
    const { setCreatedQuestions } = useQuestionStore()

    const navigate = useNavigate()
    const location = useLocation()

    const isSideBarOpen = useMemo(() => {
        return ![Page.QUESTION_RESPONDER_OLD_URL].includes(location.pathname as Page)
    }, [location.pathname])

    const goToNavItem = (nav: NavItem) => {
        setSelectedNavbar(nav.id)
        navigate(nav.uri)
    }

    const logoutUser = () => {
        setCreatedQuestions()
        logout()
    }

    return (
        <div className={`flex flex-col bg-[#F7F7F7]`}>
            <div>{loading && <Spinner />}</div>
            <div className="min-h-screen flex flex-row">
                {/* Left Menu */}
                <div className={`${isSideBarOpen ? 'block' : 'hidden'} z-[11]`}>
                    <div
                        className={`h-screen ${
                            isRightMenuExpanded
                                ? 'min-w-[310px] w-[310px]'
                                : 'min-w-[150px] w-[150px]'
                        } hidden lg:block`}
                    >
                        <div
                            className={`flex flex-col h-full pl-[40px] pr-[10px] pb-[30px] overflow-y-auto ${
                                isRightMenuExpanded ? 'block' : 'hidden'
                            }`}
                            style={{
                                background:
                                    'var(--Bg-for-Questions, linear-gradient(312deg, rgba(107, 178, 255, 0.19) 9.85%, rgba(0, 122, 255, 0.13) 35.98%, rgba(107, 178, 255, 0.13) 70.39%, rgba(0, 122, 255, 0.19) 95.04%), #FFF)',
                                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                            }}
                        >
                            <div className="flex w-full pl-[15px] mt-10">
                                {/* <p className="text-base text-[#5C8EC6]">
                                    Real-time, audio feedback
                                </p> */}
                                <button
                                    className="pl-[26px]"
                                    onClick={() => {
                                        setIsRightMenuExpanded(false)
                                    }}
                                >
                                    <img src="/asset/right-menu-arrow-icon-left.svg" />
                                </button>
                            </div>
                            <div className="flex w-full justify-center pl-[15px] mt-10">
                                <img
                                    src="/asset/toneai-logo.png"
                                    alt="logo"
                                    className="w-[80px] h-[80px]"
                                />
                            </div>
                            <div className="flex flex-col gap-[40px] mt-14 px-[15px]">
                                {sideNavs.map((nav: NavItem) => (
                                    <div className="h-6 flex flex-row gap-4" key={nav.id}>
                                        <div className="w-2.5">
                                            {selectedNavbar === nav.id && (
                                                <div className="h-6 bg-[#007AFF]" />
                                            )}
                                        </div>
                                        <button
                                            onClick={() => goToNavItem(nav)}
                                            className="flex flex-row items-center gap-4 text-[#007AFF] text-lg font-semibold"
                                        >
                                            <img src={nav.icon} />
                                            <p>{nav.title}</p>
                                        </button>
                                    </div>
                                ))}
                            </div>
                            {/* <div className="pr-[30px] flex-1">
                                <div className="mt-[150px]">
                                    <p
                                        className="text-[23px] text-[#001F40] font-bold mb-2 flex items-center gap-1"
                                        style={{
                                            fontFamily: 'Raleway',
                                        }}
                                    >
                                        💡 Did you know?
                                    </p>
                                    <p className="text-base text-[#001F40]">
                                        You can share links anywhere (embed into websites, share via
                                        iMessage). Capture real-time sentiment from tone and
                                        inflection to build empathy with your customers faster.
                                    </p>
                                </div>
                                <div className="mt-[50px]">
                                    <p
                                        className="text-[23px] text-[#001F40] font-bold mb-2"
                                        style={{
                                            fontFamily: 'Raleway',
                                        }}
                                    >
                                        🛠 Have feedback?
                                    </p>

                                    <a
                                        href="https://web.nodeio.app/questions/e6c6f622-bf14-42bb-a8f0-1b332188fa8a"
                                        target="_blank"
                                        className="text-base font-bold text-[#007AFF] dark:text-blue-500 hover:underline"
                                    >
                                        Tell us about it!
                                    </a>
                                    <div className="flex justify-center mt-3">
                                        <QRCode
                                            value={`https://web.nodeio.app/ria94075/what-should-we-know-about-your-190228`}
                                            size={180}
                                            style={{
                                                padding: '20px',
                                            }}
                                        />
                                    </div>
                                </div>
                            </div> */}
                            {/* <p className="text-base text-[#001F40A8] pb-6">hello@nodeio.xyz</p> */}
                        </div>
                        <div className={`px-2 ${!isRightMenuExpanded ? 'block' : 'hidden'}`}>
                            <button
                                className="pl-[75px] h-[62px] mt-10"
                                onClick={() => {
                                    setIsRightMenuExpanded(true)
                                }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="20"
                                    viewBox="0 0 30 20"
                                    fill="none"
                                >
                                    <path
                                        d="M2 2H28"
                                        stroke="#007AFF"
                                        strokeWidth="3"
                                        strokeLinecap="round"
                                    />
                                    <path
                                        d="M2 10H28"
                                        stroke="#007AFF"
                                        strokeWidth="3"
                                        strokeLinecap="round"
                                    />
                                    <path
                                        d="M2 18H28"
                                        stroke="#007AFF"
                                        strokeWidth="3"
                                        strokeLinecap="round"
                                    />
                                </svg>
                            </button>
                            <div className="flex flex-col pl-[50px] gap-[60px] mt-[60px]">
                                {sideNavs.map((nav: NavItem) => (
                                    <div
                                        key={nav.id}
                                        className="h-6 w-[50px] flex flex-row justify-start gap-4"
                                    >
                                        <div className="w-2.5">
                                            {selectedNavbar === nav.id && (
                                                <div className="h-6 bg-[#007AFF]" />
                                            )}
                                        </div>
                                        <button onClick={() => goToNavItem(nav)}>
                                            <img
                                                src={
                                                    selectedNavbar === nav.id
                                                        ? nav.activeIcon
                                                        : nav.icon
                                                }
                                            />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                {/* Search Bar */}
                <div className="">
                    <div
                        className={`absolute ${
                            blurBackground ? 'z-[100000]' : 'z-[12]'
                        } ml-12 mt-4 w-3/5`}
                    >
                        <SearchComponent setBlurBackground={setBlurBackground} />
                    </div>
                    {/* <div className={`mt-20 ${blurBackground ? 'blur' : ''}`}>{children}</div> */}
                </div>
                <div
                    className="flex-1 h-screen overflow-y-auto w-full lg:w-fit pb-[50px] sm:pb-0"
                    onClick={() => setIsRightMenuExpanded(false)}
                >
                    {/* Header */}
                    <div className="flex justify-end items-center h-[130px] sticky top-0 bg-[#F7F7F7] z-[11]">
                        <div className="flex flex-col mr-3">
                            <p className="text-[#8092A5] text-base text-right">
                                Welcome back, {user?.first_name}
                            </p>
                            {/* <p className="text-[#007AFF] text-xs">Having an issue?</p> */}
                            {/* <a
                                href="https://web.nodeio.app/ria94075/what-should-we-know-about-your-190228"
                                target="_blank"
                                className="text-xs text-[#007AFF] dark:text-blue-500 hover:underline text-right"
                            >
                                Having an issue?
                            </a> */}
                        </div>
                        <Dropdown
                            theme={{
                                floating: {
                                    base: '!w-[160px] !left-[unset] !right-3 sm:!right-[40px] lg:!right-[120px] !top-[95px] !transform-none z-10 rounded-[10px] profile-top-right',
                                },
                            }}
                            label=""
                            placement="bottom"
                            renderTrigger={() => (
                                <button
                                    className="flex items-center text-sm font-medium text-[#8092A5] mr-3 sm:mr-[40px] lg:mr-[120px] h-fit"
                                    type="button"
                                >
                                    <img
                                        src={
                                            user?.profile_photo?.url ??
                                            'https://thetrustapp.s3.us-east-2.amazonaws.com/profile-icon-96x96.png'
                                        }
                                        width={40}
                                        height={40}
                                        className="z-10 rounded-full object-cover h-10 w-10"
                                        alt="profile_photo"
                                    />
                                    {/* <p className="text-sm color-[#8092A5] ml-2">
                                        {user.first_name} {user.last_name}
                                    </p> */}
                                    <svg
                                        className="w-2.5 h-2.5 ml-2.5"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 10 6"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="m1 1 4 4 4-4"
                                        />
                                    </svg>
                                </button>
                            )}
                        >
                            {[
                                ...sideNavs,
                                {
                                    id: 'profiles',
                                    title: 'Switch my profile',
                                    uri: Page.SELECT_PROFILE,
                                },
                            ].map((nav: NavItem) => (
                                <Dropdown.Item
                                    key={nav.id}
                                    onClick={() =>
                                        nav.id === 'profiles'
                                            ? navigate(nav.uri, { state: { logged: true } })
                                            : navigate(nav.uri)
                                    }
                                    theme={{
                                        base: 'flex items-center justify-start py-2 px-4 text-sm text-gray-700 cursor-pointer w-full hover:bg-transparent dark:text-gray-200 focus:outline-none dark:hover:text-white focus:bg-transparent dark:focus:text-white',
                                    }}
                                >
                                    <span className="block text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">
                                        {nav.title}
                                    </span>
                                </Dropdown.Item>
                            ))}
                            <Dropdown.Divider></Dropdown.Divider>
                            <Dropdown.Item
                                onClick={logoutUser}
                                theme={{
                                    base: 'flex items-center justify-start py-2 px-4 text-sm text-gray-700 cursor-pointer w-full hover:bg-transparent dark:text-gray-200 focus:outline-none dark:hover:text-white focus:bg-transparent dark:focus:text-white',
                                }}
                            >
                                <span className="block text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">
                                    Log out
                                </span>
                            </Dropdown.Item>
                        </Dropdown>
                    </div>
                    <div className="flex flex-col">
                        {/* <div className="absolute z-[100000] w-4/5">
                            <SearchComponent setBlurBackground={setBlurBackground} />
                        </div> */}
                        <div className={`mt-10 ${blurBackground ? 'blur' : ''}`}>{children}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Layout
