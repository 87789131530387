import { useUserStore } from '../context/user/store'
import { Page } from '../enums/Page'
import CampaignLandingPage from '../pages/CampaignLandingPage'
import ConsentPublicityRelease from '../pages/ConsentPublicityRelease'
import CreateLink from '../pages/CreateLink'
import CreateReelPage from '../pages/CreateReelPage'
import EditAudio from '../pages/EditAudio'
import EditInterview from '../pages/EditInterview'
import EditProfile from '../pages/EditProfile'
import EditQuestions from '../pages/EditQuestions'
import Home from '../pages/Home'
import InterviewLandingPage from '../pages/InterviewLandingPage'
import InterviewResponder from '../pages/InterviewResponder'
import InterviewResponseLandingPage from '../pages/InterviewResponseLandingPage'
import LinkExpired from '../pages/LinkExpired'
import QuestionResponsesMore from '../pages/MoreResponses'
import PreviewQuestion from '../pages/PreviewQuestion'
import Privacy from '../pages/Privacy'
import Profile from '../pages/Profile'
import Profiles from '../pages/Profiles'
import PublicityRelease from '../pages/PublicityRelease'
import QuestionLandingPage from '../pages/QuestionLandingPage'
import QuestionResponder from '../pages/QuestionResponder'
import SelectProfile from '../pages/SelectProfile'
import Signin from '../pages/Signin'
import Signup from '../pages/Signup'
import StoryboardLandingPage from '../pages/Storyboard'
import SuccessfullySavedResponses from '../pages/SuccessfullySavedResponses'
import Terms from '../pages/Terms'
import ThankYouFansPage from '../pages/ThankYouFans'
import Users from '../pages/Users'
import Layout from './Layout'
import RouterGuard from './RouterGuard'
import { AxiosError } from 'axios'
import { Fragment, useEffect, useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

interface Route {
    path: Page | any
    component?: JSX.Element
    guard?: any
    layout?: any
    routes?: Route[]
    exact?: boolean
}

const renderRoutes = (routes: Route[] = []): JSX.Element => (
    <Routes>
        {routes.map((route, index) => {
            const Guard = route.guard || Fragment
            const Layout = route.layout || Fragment

            return (
                <Route
                    key={index}
                    {...route}
                    element={
                        <Guard>
                            <Layout>
                                {route.routes ? renderRoutes(route.routes) : route.component}
                            </Layout>
                        </Guard>
                    }
                />
            )
        })}
    </Routes>
)

export const routes: Route[] = [
    {
        path: Page.SIGN_IN,
        component: <Signin />,
        exact: true,
    },
    {
        path: Page.SIGN_UP,
        component: <Signup />,
        exact: true,
    },
    {
        path: Page.QUESTION_RESPONDER_OLD_URL,
        component: <QuestionResponder />,
        exact: true,
    },
    {
        path: Page.SUCCESSFULLY_SAVED_RESPONSES,
        component: <SuccessfullySavedResponses />,
        exact: true,
    },
    {
        path: Page.LINK_EXPIRED,
        component: <LinkExpired />,
        exact: true,
    },
    {
        path: Page.PUBLICITY_RELEASE,
        component: <PublicityRelease />,
        exact: true,
    },
    {
        path: Page.CONSENT_PUBLICITY_RELEASE,
        component: <ConsentPublicityRelease />,
        exact: true,
    },
    {
        path: Page.PRIVACY,
        component: <Privacy />,
        exact: true,
    },
    {
        path: Page.TERMS,
        component: <Terms />,
        exact: true,
    },
    {
        path: Page.QUESTION_PREVIEW,
        component: <PreviewQuestion />,
        guard: RouterGuard,
        exact: true,
    },
    {
        path: Page.SELECT_PROFILE,
        component: <SelectProfile />,
        guard: RouterGuard,
        exact: true,
    },
    {
        path: Page.HOME,
        exact: true,
        component: <Home />,
        guard: RouterGuard,
        layout: Layout,
    },
    {
        path: Page.PROFILES,
        exact: true,
        component: <Profiles />,
        guard: RouterGuard,
        layout: Layout,
    },
    {
        path: Page.PROFILE,
        exact: true,
        guard: RouterGuard,
        layout: Layout,
        component: <Profile />,
    },
    {
        path: Page.PROFILE_EDIT,
        exact: true,
        guard: RouterGuard,
        layout: Layout,
        component: <EditProfile />,
    },
    {
        path: Page.USERS,
        exact: true,
        guard: RouterGuard,
        layout: Layout,
        component: <Users />,
    },
    {
        path: Page.QUESTION_CREATE,
        component: <CreateLink />,
        exact: true,
        guard: RouterGuard,
        layout: Layout,
    },
    {
        path: Page.QUESTION_RESPONDER,
        component: <QuestionResponder />,
        exact: true,
    },
    {
        path: Page.INTERVIEW_RESPONDER,
        component: <InterviewResponder />,
        exact: true,
    },
    {
        path: Page.STORYBOARD,
        component: <StoryboardLandingPage />,
        exact: true,
    },
    {
        path: Page.THANK_YOU_FANS,
        component: <ThankYouFansPage />,
        exact: true,
    },
    {
        path: Page.QUESTION_LANDING_PAGE,
        component: <QuestionLandingPage />,
        exact: true,
        guard: RouterGuard,
        layout: Layout,
    },
    {
        path: Page.QUESTION_LANDING_PAGE_MORE,
        component: <QuestionResponsesMore />,
        exact: true,
        guard: RouterGuard,
    },
    {
        path: Page.EDIT_AUDIO,
        component: <EditAudio />,
        exact: true,
        guard: RouterGuard,
    },
    {
        path: Page.EDIT_QUESTIONS,
        component: <EditQuestions />,
        exact: true,
        guard: RouterGuard,
        layout: Layout,
    },
    {
        path: Page.INTERVIEW_LANDING_PAGE,
        component: <InterviewLandingPage />,
        exact: true,
        guard: RouterGuard,
        layout: Layout,
    },
    {
        path: Page.INTERVIEW_RESPONSE_LANDING_PAGE,
        component: <InterviewResponseLandingPage />,
        exact: true,
        guard: RouterGuard,
        layout: Layout,
    },
    {
        path: Page.EDIT_INTERVIEW,
        component: <EditInterview />,
        exact: true,
        guard: RouterGuard,
        layout: Layout,
    },
    {
        path: Page.CAMPAIGN_LANDING_PAGE,
        component: <CampaignLandingPage />,
        exact: true,
        guard: RouterGuard,
        layout: Layout,
    },
    {
        path: Page.CREATE_REEL,
        component: <CreateReelPage />,
        exact: true,
        guard: RouterGuard,
        layout: Layout,
    },
    {
        path: '*',
        component: <Navigate to="/" />,
    },
]

const RouterWrapper = (): JSX.Element | null => {
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const { fetchUser } = useUserStore()

    useEffect(() => {
        const fetchData = async () => {
            try {
                await fetchUser()
            } catch (e: unknown) {
                const error = e as AxiosError<any>
                if (error.response?.status === 401 || error.response?.status === 403) {
                    return
                }
                throw e
            } finally {
                setIsLoading(false)
            }
        }
        void fetchData()
    }, [])

    if (isLoading) {
        return null
    }

    return renderRoutes(routes)
}
export default RouterWrapper
