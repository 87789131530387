import { Response } from '../pages/QuestionResponder'
import { Question } from '../types/Question'
//import { toMMHH } from '../utils/toMMHH'
//import getBlobDuration from 'get-blob-duration'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactPlayer from 'react-player'

interface ResponsePreviewVideoProps {
    question: Question | null
    responseForSelectedQuestion: Response
    onReRecord: () => void
}

const ResponsePreviewVideo = ({
    responseForSelectedQuestion,
    onReRecord,
}: ResponsePreviewVideoProps): JSX.Element => {
    //const [showPlayer, setShowPlayer] = useState<boolean>(false)
    //const [videoLength, setVideoLength] = useState<string>('')
    const [openModal, setOpenModal] = useState<boolean>(false)

    const { t } = useTranslation()

    // useEffect(() => {
    //     ;(async function () {
    //         const duration = await getBlobDuration(
    //             responseForSelectedQuestion.formData?.get('file') as Blob,
    //         )
    //         //setVideoLength(toMMHH(duration))
    //     })()
    // }, [responseForSelectedQuestion.formData])

    const toggleModal = () => setOpenModal(!openModal)

    return (
        <React.Fragment>
            <div
                className="rounded-lg p-0 md:p-2 w-full md:w-[720px] flex flex-col justify-center items-center"
                style={{
                    background:
                        'var(--card, linear-gradient(313deg, rgba(107, 178, 255, 0.19) 7.36%, rgba(0, 122, 255, 0.13) 33.85%, rgba(107, 178, 255, 0.13) 68.75%, rgba(0, 122, 255, 0.19) 93.75%), #0D0D0D)',
                }}
            >
                <>
                    <div className="flex flex-row items-center w-[90%] mt-0 sm:mt-4">
                        <ReactPlayer
                            controls={true}
                            pip={false}
                            playsinline={true}
                            url={URL.createObjectURL(
                                responseForSelectedQuestion.formData?.get('file') as Blob,
                            )}
                        />
                    </div>
                    <div className="flex flex-row justify-end items-center mt-4 mb-4">
                        <button
                            className="text-[#BFDEFF] text-base font-bold"
                            onClick={toggleModal}
                        >
                            <div className="flex flex-row gap-1">
                                <img src="/asset/refresh-icon.svg" alt="record_button" />
                                {t('responder_preview.re_record')}
                            </div>
                        </button>
                    </div>
                </>
            </div>
            <div
                className={`fixed top-0 left-0 w-full h-full bg-[#000000c7] flex justify-center items-center z-20 ${
                    openModal ? 'fixed' : 'hidden'
                }`}
            >
                <div
                    className="relative w-[350px] sm:w-[480px] h-[200px] bg-white px-[30px] sm:px-[60px] sm:pr-[40px] py-[30px] rounded-2xl flex flex-col"
                    style={{ boxShadow: '0px 4px 4px 0px #00000040' }}
                >
                    <div className="flex-1">
                        <h2 className="text-[#007AFF] text-[25px] leading-10">
                            {t('common.are_you_sure')}
                        </h2>
                        <p className="text-[#0D0D0D] text-[13px]">
                            {t('responder_preview.original_response_will_be_lost')}.
                        </p>
                    </div>

                    <div className="flex self-end gap-7">
                        <button
                            className="text-[#007AFF] text-base font-bold"
                            onClick={toggleModal}
                        >
                            {t('responder_preview.no_cancel')}
                        </button>
                        <button
                            className="text-[#007AFF] text-base font-bold flex"
                            onClick={() => {
                                toggleModal()
                                onReRecord()
                            }}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                className="mr-1"
                            >
                                <g clipPath="url(#clip0_5948_13162)">
                                    <path
                                        d="M19 8L15 12H18C18 15.31 15.31 18 12 18C10.99 18 10.03 17.75 9.2 17.3L7.74 18.76C8.97 19.54 10.43 20 12 20C16.42 20 20 16.42 20 12H23L19 8ZM6 12C6 8.69 8.69 6 12 6C13.01 6 13.97 6.25 14.8 6.7L16.26 5.24C15.03 4.46 13.57 4 12 4C7.58 4 4 7.58 4 12H1L5 16L9 12H6Z"
                                        fill="#007AFF"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_5948_13162">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            {t('responder_preview.yes_re_record')}
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ResponsePreviewVideo
