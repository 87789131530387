/* eslint-disable indent */
import { useQuestionStore } from '../context/question/store'
import { Mandatory } from '../enums/Mandatory'
import { Question } from '../types/Question'
import { upperCaseLeading } from '../utils/upperCaseLeading'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface AddMoreInformationProps {
    requiredToAllResponds: boolean
    question: Question | null
    handleSubmit: () => void
    isSubmitted: boolean
    requireFirstName?: Mandatory
    requireEmail?: Mandatory
    nonRespondedRequiredQuestions: Question[]
    isLastQuestionRequired: boolean
    isLastQuestionResponded: boolean
    firstName: string
    setFirstName: (firstName: string) => void
    lastName: string
    setLastName: (lastName: string) => void
    email: string
    setEmail: (email: string) => void
}

const AddMoreInformation = ({
    handleSubmit,
    question,
    requiredToAllResponds,
    isSubmitted,
    requireFirstName,
    requireEmail,
    nonRespondedRequiredQuestions,
    isLastQuestionRequired,
    isLastQuestionResponded,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
}: AddMoreInformationProps): JSX.Element => {
    const [emailError, setEmailError] = useState<string>('')
    const [claimed, setClaimed] = useState<boolean>(false)
    const [showError, setShowError] = useState<boolean>(false)
    const {
        state: { accepted },
    } = useQuestionStore()

    const { t } = useTranslation()

    const validateEmail = (email: string): boolean => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return emailRegex.test(email)
    }

    let errorMessage: string = ''
    let singularOrPlural: 'singular' | 'plural' = 'singular'

    if (nonRespondedRequiredQuestions.length === 1) {
        errorMessage = question?.is_multi_question_link
            ? `${t('common.question')} ${nonRespondedRequiredQuestions[0].question_sequence}`
            : t('common.question')
    }
    if (nonRespondedRequiredQuestions.length > 1) {
        errorMessage = `${t('common.questions')} ${nonRespondedRequiredQuestions
            .map((question) => question.question_sequence)
            .join(', ')}`
        singularOrPlural = 'plural'
    }
    if (requireFirstName === Mandatory.REQUIRED && (!firstName || !lastName)) {
        if (errorMessage) {
            errorMessage = `${errorMessage}, ${t('add_more_info.and_your_name')}`
            singularOrPlural = 'plural'
        } else {
            errorMessage = t('add_more_info.your_name')
        }
    }
    if (requireEmail === Mandatory.REQUIRED && !email) {
        if (errorMessage) {
            errorMessage = `${errorMessage.replace(t('common.and'), '')}, ${t(
                'add_more_info.and_your_email',
            )}`
            singularOrPlural = 'plural'
        } else {
            errorMessage = t('add_more_info.your_email')
        }
    }

    if (errorMessage) {
        errorMessage = `${errorMessage} ${
            singularOrPlural === 'singular' ? t('common.is') : t('common.are')
        } ${t('common.required')}`
    }

    let showAddMoreInformation = false
    if (!isLastQuestionRequired) {
        showAddMoreInformation = true
    } else if (isLastQuestionRequired && isLastQuestionResponded) {
        showAddMoreInformation = true
    }

    if (
        requireFirstName &&
        requireFirstName === Mandatory.HIDDEN &&
        requireEmail &&
        requireEmail === Mandatory.HIDDEN
    )
        showAddMoreInformation = false

    const translateEmailError = (error: string): string => {
        if (error === 'Please enter a valid email address') {
            return t('add_more_info.please_enter_valid_email_address')
        }
        return error
    }

    return (
        <div>
            {showAddMoreInformation && (
                <div>
                    <div className="w-full md:w-[720px] flex flex-col mt-6 mb-3">
                        <div className="flex flex-col sm:flex-row gap-2">
                            <p className="text-[#001F40] text-sm font-lato">
                                {t('add_more_info.add_your_information')}
                            </p>{' '}
                            {question?.question_settings?.show_touch_with_you_message && (
                                <svg
                                    className="hidden sm:block"
                                    width="1"
                                    height="25"
                                    viewBox="0 0 1 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M0.5 1L0.499999 24"
                                        stroke="black"
                                        strokeOpacity="0.38"
                                        strokeWidth="0.5"
                                        strokeLinecap="round"
                                    />
                                </svg>
                            )}
                            {question?.question_settings?.show_touch_with_you_message && (
                                <p className="text-[#001f4061] text-sm font-lato">
                                    {t('add_more_info.let_touch_with_you')
                                        .replace(
                                            '{first_name}',
                                            (question as Question)?.author?.first_name,
                                        )
                                        .replace(
                                            '{last_name}',
                                            (question as Question)?.author?.last_name,
                                        )}
                                </p>
                            )}
                        </div>
                        {requireFirstName && requireFirstName !== Mandatory.HIDDEN && (
                            <div className="flex flex-col sm:flex-row gap-3 mt-7">
                                <input
                                    className="min-w-[180px] bg-[#d9d9d940] text-sm px-5 h-10 rounded-[10px] text-[#001F40] focus:outline-none"
                                    placeholder={`${t('add_more_info.first_name')} ${
                                        requireFirstName === Mandatory.REQUIRED
                                            ? `(${upperCaseLeading(t('common.required'))})`
                                            : `(${upperCaseLeading(t('common.optional'))})`
                                    }`}
                                    value={firstName}
                                    onChange={(e) => {
                                        setFirstName(e.target.value)
                                    }}
                                />
                                <input
                                    className="min-w-[180px] bg-[#d9d9d940] text-sm px-5 h-10 py-1 rounded-[10px] text-[#001F40] focus:outline-none"
                                    placeholder={`${t('add_more_info.last_name')} ${
                                        requireFirstName === Mandatory.REQUIRED
                                            ? `(${upperCaseLeading(t('common.required'))})`
                                            : `(${upperCaseLeading(t('common.optional'))})`
                                    }`}
                                    value={lastName}
                                    onChange={(e) => {
                                        setLastName(e.target.value)
                                    }}
                                />
                            </div>
                        )}
                    </div>
                    {requireEmail && requireEmail !== Mandatory.HIDDEN && (
                        <div className="w-full md:w-[720px] flex flex-col mt-8">
                            <div className="flex flex-col sm:flex-row gap-3">
                                <input
                                    className="min-w-[180px] bg-[#d9d9d940] text-sm px-5 h-10 rounded-[10px] text-[#001F40] focus:outline-none"
                                    placeholder={`${t('add_more_info.email')} ${
                                        requireEmail === Mandatory.REQUIRED
                                            ? `(${upperCaseLeading(t('common.required'))})`
                                            : `(${upperCaseLeading(t('common.optional'))})`
                                    }`}
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value)
                                    }}
                                />
                            </div>
                            <div className="h-6">
                                {emailError && (
                                    <p className="text-[13px] text-[#FF0000] font-bold mt-1">
                                        {translateEmailError(emailError)}
                                    </p>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            )}
            {errorMessage && showError && (
                <div className="h-[56px] mt-8">
                    <p className="px-5 py-4 bg-[#B6D6FA5E] text-[#007AFF] font-lato">
                        🚨 {errorMessage}
                    </p>
                </div>
            )}
            {claimed && (
                <div className="h-[56px] mt-3">
                    <p className="px-5 py-4 bg-[#B6D6FA5E] text-[#007AFF] font-lato w-fit">
                        🚨 {t('add_more_info.only_claimed_accounts_can_accept_responses')}
                    </p>
                </div>
            )}
            {accepted && (
                <div className="mt-6 flex flex-row justify-end items-center w-full md:w-[720px] mb-4">
                    {requiredToAllResponds && (
                        <p className="text-sm text-[#007AFF] mr-3">
                            {t('add_more_info.please_answer_all_required_questions')}.
                        </p>
                    )}
                    <button
                        className={`text-[#BFDEFF] ${
                            !accepted || isSubmitted
                                ? 'bg-[#add4ff] text-[#AFAFAF]'
                                : 'bg-[#007AFF] '
                        } px-6 py-2 rounded-[10px] fixed top-20 z-10000`}
                        disabled={!accepted || isSubmitted}
                        onClick={() => {
                            setShowError(false)
                            if (errorMessage !== '') {
                                setShowError(true)
                                return
                            }
                            if (isSubmitted) return
                            setEmailError('')
                            if (email && !validateEmail(email)) {
                                setEmailError('Please enter a valid email address')
                                setShowError(true)
                                return
                            }
                            if (!question?.author.account_claimed) {
                                setClaimed(true)
                                return
                            }
                            handleSubmit()
                        }}
                    >
                        {t('common.submit')}
                    </button>
                </div>
            )}
            {question?.author.id === '24382cfe-5435-4e44-b5cf-86e547ed99ea' && (
                <div className="flex flex-row-reverse">
                    <p className="text-[#0C1B2C] w-80 text-sm text-left mt-2 justify-end">
                        {t('add_more_info.bears_custom_consent_text')}
                        <span>
                            <a
                                href="https://web.nodeio.app/publicity-release"
                                className="text-sm font-bold text-[#007AFF]"
                                target="_blank"
                            >
                                {t('add_more_info.bears_custom_consent_link')}
                            </a>
                        </span>
                    </p>
                </div>
            )}
        </div>
    )
}

export default AddMoreInformation
