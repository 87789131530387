enum ActionTypes {
    SET_INTERVIEW_IDS = 'SET_INTERVIEW_IDS',
    SET_SURVEY_IDS = 'SET_SURVEY_IDS',
}

interface SetInterviewIdsAction {
    type: ActionTypes.SET_INTERVIEW_IDS
    payload: {
        interviewIds: string[]
    }
}

interface SetSurveyIdsAction {
    type: ActionTypes.SET_SURVEY_IDS
    payload: {
        surveyIds: string[]
    }
}

export type RagAction = SetInterviewIdsAction | SetSurveyIdsAction

export default ActionTypes
